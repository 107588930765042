import React, { useState, useEffect } from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import { Accordion } from "react-bootstrap";

import { getMyProjects, getMyProposals } from "../services/getRequest";
import Layout from "../components/layout";
import ProposalsOverview from "../components/proposals-overview";
import ProjectsOverview from "../components/project/projects-overview";
import { Spinner } from "react-bootstrap";
import _ from "lodash";

const MyProposals = () => {
  const [allProposals, setAllProposals] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getMyProjects().then(
      (value) => {
        setAllProjects(_.groupBy(value.projects, "status"));
      },
      (reason) => {
        console.error(reason);
      }
    );

    getMyProposals().then(
      (value) => {
        setAllProposals(value.projects);
      },
      (reason) => {
        // rejection
        console.error(reason);
      }
    );
    setLoading(false);
  }, []);

  return (
    <Layout>
      <div className="row">
        <h1 className="block-title">
          <FormattedMessage
            id="my_proposals_and_projects"
            defaultMessage="My Proposals and Projects"
          />
        </h1>
      </div>
      {loading ? (
        <div className="row">
          <div className="col">
            <h2 className="anchor-title">
              <FormattedMessage id="loading" />{" "}
              <FormattedMessage id="my_proposals_and_projects" />
            </h2>
            <Spinner animation="border" variant="secondary" role="status">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </Spinner>
          </div>
        </div>
      ) : (
        <div>
          {allProposals?.length > 0 ? (
            <div className="row" data-id="proposals">
              <h2 className="anchor-title">
                <FormattedMessage
                  id="my_proposals"
                  defaultMessage="My Proposals"
                />
              </h2>
              <ProposalsOverview proposals={allProposals}></ProposalsOverview>
            </div>
          ) : (
            <div className="row" data-id="projects"></div>
          )}
          <div className="row" data-id="proposals">
            <h2 className="anchor-title">
              <FormattedMessage
                id="live_projects"
                defaultMessage="Ongoing Projects"
              />
            </h2>
          </div>
          {JSON.stringify(allProjects) !== "{}" ? (
            <Accordion
              className="mb-3"
              defaultActiveKey={Object.entries(allProjects)}
            >
              {Object.entries(allProjects).map(([key, value, index]) => (
                <Accordion.Item eventKey={key}>
                  <Accordion.Header>
                    <span className="badge bg-secondary me-1 rounded-pill ">
                      {value.length}
                    </span>
                    {`${key}`}
                  </Accordion.Header>
                  <Accordion.Body>
                    <ProjectsOverview projects={value}></ProjectsOverview>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : (
            <div className="row" data-id="projects"></div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default MyProposals;
